import React, { useState } from 'react'
import Layout from '../../components/Layout';
import Head from '../../components/Head';
import { graphql } from 'gatsby';

const emphasisStyle = {
    fontWeight: "900",
    fontSize: "7rem"
}

export default function ContactUs({ data }) {
    
    const { email, contact } = data.site.siteMetadata
    const [formState, setFormState] = useState({
        InputName: "",
        InputEmail: "",
        InputCompany: "",
        PhoneNumber: "",
        Message: ""
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }


    const handleChange = e => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }

    const handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...formState })
        })
            .then(() => alert("Your message has been received. We will contact you soon."))
            .catch(error => alert(error));
        e.preventDefault();
    }
    return (
        <Layout>
            <Head title="Contact Us" />
            <div className="container shadow-lg">
                <div className="row mb-5">
                    <div className="col-lg-6 dark-contrast-border bg-dark text-white">
                        <div className="brand-color mt-4 me-2 ms-2 mb-2">
                            <div className="fw-bolder">
                                <h1 style={emphasisStyle} className="p-3">hello.</h1>
                            </div>
                        </div>
                        <p className="fw-light text-muted mt-4 me-2 ms-2 mb-2 p-3 lh-lg">
                            Have an enquiry ?<br></br>
                            Fill out the form and hear from us within the next 24 hours.<br></br>
                            We would love to hear from you.<br></br>
                            <b>Or</b><br></br>
                            You can directly mail us at <a href="mailto:info@technoparkinc.com"><span className="brand-color">{ email }</span></a><br></br><b>Or</b><br></br>Call Us at <span className="brand-color">{ contact }</span>
                        </p>
                        <div className="fw-light text-muted mt-4 me-2 ms-2 mb-2 p-3 lh-lg">
                            <div className="row">
                                <div className="col order-2">
                                    <span className="fs-5"><i className="bi bi-pin-map"></i> Our Address</span><br></br>
                                    Block C, Plot No. 27 & 28,<br></br>
                                    New Shivalik Nagar,<br></br>
                                    Near BSP Office,<br></br>
                                    Haridwar ( 249403 ), Uttarakhand,<br></br>
                                    India
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 p-4 text-muted dark-contrast">
                        <form className="needs-validation" name="tpiContact" noValidate data-netlify="true" method="POST" onSubmit={handleSubmit} data-netlify-honeypot="bot-field">
                            <input type="hidden" name="form-name" value="tpiContact" />
                            <div className="mb-3">
                                <label htmlFor="InputName" className="form-label">Full Name <span style={{ color: "red" }}>*</span></label>
                                <input type="text" className="form-control border-0" name="InputName" id="InputName" aria-describedby="nameHelp" required onChange={handleChange} value={formState.inputName} />
                                <div id="nameHelp" className="form-text">We'll never share your name with anyone else.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputEmail" className="form-label">Email address <span style={{ color: "red" }}>*</span></label>
                                <input type="email" className="form-control border-0" name="InputEmail" id="InputEmail" aria-describedby="emailHelp"
                                    required onChange={handleChange} value={formState.inputEmail} />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="InputCompany" className="form-label">Company <span style={{ color: "red" }}>*</span></label>
                                <input type="text" className="form-control border-0" name="InputCompany" id="InputCompany" required onChange={handleChange} value={formState.inputCompany} />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="PhoneNumber" className="form-label">Phone Number <small className="text-muted">[
                                    Optional ]</small></label>
                                <input type="text" className="form-control border-0" name="PhoneNumber" id="PhoneNumber" aria-describedby="phoneHelp" onChange={handleChange} value={formState.phoneNumber} />
                                <div id="phoneHelp" className="form-text">We'll never share your phone number with anyone else.
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="Message" className="form-label">Message <span style={{ color: "red" }}>*</span></label>
                                <textarea className="form-control border-0" name="Message" id="Message" rows="10" required onChange={handleChange} value={formState.message}></textarea>
                            </div>
                            <button type="submit" className="btn primary-btn ps-5 pe-5">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
query SiteInfoQuery{
    site{
        siteMetadata {
            email,
            contact
        }
    }
}
`